import axios from "axios";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import InventoryList from "../inventory/InventoryList";

export default function FamilyList() {
    const { accountName, familyId } = useParams();

    const [allPokemon, setAllPokemon] = useState();

    useEffect(() => {
        async function getAllPokemon() {
            const response = await axios(`/accounts/${accountName}/families/${familyId}`);
            setAllPokemon(response.data);
        }
        getAllPokemon();
    }, [accountName, familyId]);

    if (!allPokemon) {
        return (<></>)
    }

    return (<div>
        <div>{familyId} Family List</div>
        <InventoryList
            inventory={allPokemon.inventory}
            />
    </div>
    )
}
