import axios from "axios";
import { useEffect, useState } from "react";

export default function HomePage() {

    const [me, setMe] = useState<any>([]);

    useEffect(() => {
        async function getMe() {
            const response = await axios(`/me`);
            setMe(response.data);
        }
        getMe();
    }, []);

    return (
        <div>
            <h1>Bill's PC — Home</h1>
            {me && me.name ? (<h2> Welcome back, {me.name}</h2>) : (<h2>Nice to meet you!</h2>)}

            {me && me.name ? (<div>
                <div>
                    <h3>Your Accounts</h3>
                    <div>
                        {me && me.accounts && me.accounts.map((a: any) => (
                            <div><a href={`accounts/${a.name}`}>{a.name}</a></div>
                        ))}
                    </div>
                    <h3>Your Friends</h3>
                    <div>Not yet implemented.</div>
                    <a href='/logout'>Log Out</a>
                </div>
            </div>) : (<a href='/login'>Log In</a>)}

        </div>
    )
}
