import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import PokemonSprite from "../../components/PokemonSprite";
import { Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "@mui/material";
export default function InventoryList({ inventory }) {
  if (!inventory) {
    return <></>;
  }

  const deleteFromInventory = async (id) => {
    await axios.delete(`/inventory/${id}`);
  };

  const setShiny = async (id, isShiny) => {
    await axios.put(`/inventory/${id}/shiny`, { newState: isShiny });
  };

  const setTradeable = async (id, isTradeable) => {
    await axios.put(`/inventory/${id}/tradeable`, { newState: isTradeable });
  };

  const DATE_WIDTH = 150;
  const IV_WIDTH = 25;
  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 60,
      renderCell: (critterRow) => (
        <div className={"pkmn-sprite-in-table"} style={{ maxWidth: "8px" }}>
          <PokemonSprite
            speciesId={critterRow.row.dex_id}
            original={critterRow.row.regionalSpecies.original}
            isShiny={critterRow.row.shiny}
            purified={critterRow.row.purified}
            region={critterRow.row.regionalSpecies.region}
            form={critterRow.row.form?.name}
            isLucky={critterRow.row.lucky}
            isShadow={critterRow.row.shadow}
            gender={critterRow.row.gender}
          />
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
      renderCell: (critterRow) => (
        <Link target={`_blank`} href={`/inventory/${critterRow.row.id}`}>
          {critterRow.row.name}
        </Link>
      ),
    },
    {
      field: "form_id",
      headerName: "Form",
      width: 75,
      renderCell: (critterRow) => <span>{critterRow.row?.form?.name}</span>,
    },
    { field: "shadow", headerName: "Shadow", width: 50 },
    { field: "purified", headerName: "Purified", width: 50 },
    { field: "lucky", headerName: "Lucky", width: 50 },
    { field: "level", headerName: "Level", width: 50 },
    { field: "gender", headerName: "Gender", width: 50 },
    { field: "iv_atk", headerName: "ATK", width: IV_WIDTH },
    { field: "iv_def", headerName: "DEF", width: IV_WIDTH },
    { field: "iv_sta", headerName: "HP", width: IV_WIDTH },
    {
      field: "shiny",
      headerName: "Shiny",
      width: 75,
      renderCell: (params) => (
        <Switch
          checked={params.row.shiny}
          onChange={() => {
            params.row.shiny = !!!params.row.shiny;
            setShiny(params.row.id, params.row.shiny);
          }}
          color="default"
        />
      ),
    },
    {
      field: "availableForTrade",
      headerName: "For Trade",
      width: 75,
      renderCell: (params) => (
        <Switch
          checked={params.row.availableForTrade}
          onChange={() => {
            params.row.availableForTrade = !!!params.row.availableForTrade;
            setTradeable(params.row.id, params.row.availableForTrade);
          }}
          color="default"
        />
      ),
    },
    {
      field: "catch_date",
      headerName: "Caught",
      width: DATE_WIDTH,
      valueFormatter: (params) =>
        params && params.value
          ? moment(params.row.catchDate).format("LL")
          : "Unknown",
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => deleteFromInventory(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", height: "24px", width: "100%" }}>
        <div style={{ flexGrow: 1, height: 500, width: "100%" }}>
          <DataGrid
            rows={inventory}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}
