import React from "react";

const regionMap = {
  7: 'ALOLA',
  8: 'GALARIAN',
  9: 'HISUIAN'
};

const formMap = {
  351: {
    // castform
    Rainy: 13,
    Sunny: 12,
    Snowy: 14,
  },
};

const formOverride = {
  150: {
    Armored: "pokemon_icon_pm0150_00_pgo_a.png",
  },
};

const female = 'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokedex/female_l.png';
const male = 'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokedex/male_l.png';

const getFormOverride = ({ speciesId, form }) => {
  return form && (
    formOverride && formOverride[speciesId] && formOverride[speciesId][form]
  );
};
export default function PokemonSprite({
  speciesId,
  isShiny,
  isShadow,
  isPurified,
  original,
  isLucky,
  form,
  region,
  costume,
  gender
}) {
  // console.log({ speciesId, isShiny, isShadow, isPurified, original, form, region, costume });
  if (!speciesId) {
    return <div></div>;
  }
  const baseStringPokemonAddressable =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon/Addressable%20Assets/"
  const addressable = [baseStringPokemonAddressable, 
    `pm${speciesId}`, 
    original ? `` : `.f${regionMap[region]}`,
    isShiny ? '.s' : '',
    `.icon.png`]

  const luckyString = 'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Friends/ui_bg_lucky_pokemon.png'; 
  const baseStringRocket =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Rocket/";

  
  return (
    <div style={{ width: 'inherit', height: 'inherit' }}>
      {/* {gender && <>
        {gender === '♂' && <img src={male} alt='male' />}
        {gender === '♀' && <img src={female} alt='female' />}
      </>}
      {isPurified && <img src={`${baseStringRocket}ic_purified.png`} />}
      {isShadow && <img src={`${baseStringRocket}ic_shadow.png`} />}
      {isLucky && <img src={luckyString} />}  */}
      <img src={addressable.join('')} alt="Sprite" />
    </div>
  );
}
