import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InventoryList from "../inventory/InventoryList";
import axios from "axios";

export default function AccountsEntry() {
    const { accountName } = useParams();

    const [account, setAccount] = useState({});
    const [forTrade, setForTrade] = useState([]);


    async function getAccount(accountName) {
        const response = await axios(`/accounts/${accountName}`);
        setAccount(response.data);
    }

    async function getForTrade(accountName) {
        const response = await axios(`/accounts/${accountName}/for-trade`);
        setForTrade(response.data);
    }

    useEffect(() => {
        getAccount(accountName);
    }, [accountName]);

    useEffect(() => {
        getForTrade(accountName);
    }, [account]);

    return (<>
        <h1>{account.name}</h1>
        <div>
            {account.team && <span>Team {account.team}</span>}
        </div>
        <h2>Available for Trade</h2>
        <InventoryList inventory={forTrade.inventory} />
    </>)
}
